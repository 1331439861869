<template>
  <v-flex xs12 :md3="!fullscreen" class="pa-2 mb-6">
    <v-card hover elevate-on-hover style="border-radius: 12px;">
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="true"
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide) in career.images" :key="slide">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img max-height="260" contain :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-list color="transparent" class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte-bold" style="font-size: 14pt;"
              >{{ career.description }}</v-list-item-title
            >
            <v-list-item-subtitle class="fonte-bold green--text" style="font-size: 16pt;">$ {{
              career.price
            }} </v-list-item-subtitle>
            <v-list-item-subtitle class="grey--text fonte-bold"> Ganhe {{ career.token_earn }} tokens </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!priceless"
              @click="openPurchase({ ...career, purchase_type: 'career' })"
              depressed
              large
              dark
              color="green"
            >
              <span class="fonte-bold" style="font-size: 14pt;"> Comprar <v-icon size="21">mdi-basket-outline</v-icon> </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <span v-if="priceless" class="pa-4 fonte-bold">
        {{ career.obs }}
      </span>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      teste_actions: ['teste', 'teste3', 'teste4']
    }
  },
  props: ["career", "fullscreen", "priceless"],
  methods: {
    ...mapActions(["openPurchase"])
  }
};
</script>
