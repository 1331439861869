<template>
  <layout :title="$route.name" :options="[]">
    <div class="expande-horizontal wrap">
      <CareerItem
        v-for="career in getCareers"
        :key="career._id"
        :career="career"
        v-show="showOnlyAProgressionCheck(career)"
      />
    </div>
  </layout> 
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CareerItem from "./components/careerItem";
export default {
  components: {
    CareerItem
  },
  computed: {
    ...mapGetters(["getCareers", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["listCareers"]),
    showOnlyAProgressionCheck(val) {
      let result = true
      
      // if(this.getLoggedUser.career_weight) {
      //   if(val.weight <= this.getLoggedUser.career_weight) {
      //     result = false
      //     return result
      //   }
      // }

      return result
    }
  },
  created() {
    this.listCareers();
  }
};
</script>
